/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function($) {

  // Use this variable to set up the common and page specific functions. If you
  // rename this variable, you will also need to rename the namespace below.
  var Sage = {
    // All pages
    'common': {
      init: function() {
        // Nav toggle
        $('.js-nav-toggle').on('click', function() {
          $(this).toggleClass('is-active');
          $('.js-nav-sec').toggleClass('is-active');
          $('.js-nav-primary-wrapper, .js-nav-primary-bg').animate({width:'toggle'},350);
          $('.js-nav-primary-wrapper .menu').delay(300).fadeToggle();
          return false;
        });

        // Close nav on body click
        $(document).click(function(event) {
          if (!$(event.target).is('.js-nav-primary-bg, .menu, .nav-primary, .menu-primary_nav-container, .menu, .menu-item')) {
            if($('.js-nav-toggle').hasClass('is-active')) {
              $('.js-nav-toggle').toggleClass('is-active');
              $('.js-nav-primary-wrapper, .js-nav-primary-bg').animate({width:'toggle'},350);
              $('.js-nav-primary-wrapper .menu').fadeToggle('fast');
            }
          }
        });


        // Nav filter toggle
        $('.js-nav-filters-toggle').on('click', function() {
          $(this).toggleClass('toggled');
          $('.js-sidebar').slideToggle();
          return false;
        });

        // Match height for store locator
        // setTimeout(function() {
        //  if ($('#wpsl-stores li').length) {
        //     $('#wpsl-stores li').matchHeight();
        //   }
        // }, 4000);

      },
      finalize: function() {
        // JavaScript to be fired on all pages, after page specific JS is fired
      }
    },
    // Home page
    'home': {
      init: function() {

        // sticky header
        var showHeaderBG = $('.js-showcase').outerHeight();
        var scrollTopPosition = 0;
        var header = $('.js-header');
        var headerHeight = $('.js-header').outerHeight() * 2;

        $(document).on('scroll', function() {
          scrollTopPosition = $(window).scrollTop();
          // console.log(scrollTopPosition);
          if(scrollTopPosition > showHeaderBG) {
            header.addClass('fixed');
          } else {
            header.removeClass('fixed');
          }

          if(scrollTopPosition > headerHeight) {
            header.addClass('headerHeight');
          } else {
            header.removeClass('headerHeight');
          }
        });


        // make carousel same height as text height right next to it
        var changeCarouselHeight = function() {
          var whatTextHeight = $('.js-what-text').outerHeight();
          if ($(window).width() > 991) {
            $('.js-item').css('height', whatTextHeight);
          } else {
            $('.js-item').css('height', 400);
          }
        };

        changeCarouselHeight();

        $(window).on('debouncedresize', function( event ) {
          changeCarouselHeight();
        });

        // Disable usage carousel
        $('.js-usage-carousel').carousel({
            interval: false
        }); 


        // // 1- Animate Aimants puissant
        // $('.js-link-1').on('click', function() {
        //   // $imageFrontBracket = $('.js-front-bracket').attr('src');
        //   // $imageBackBracket = $('.js-back-bracket').attr('src');
        //   // $imageFullBracket = $('.js-full-bracket').attr('src');
        //   $('.js-front-bracket').addClass('transform');

        //   setTimeout(function(){
        //     $('.js-front-bracket').removeClass('transform');
        //   }, 3000);

        //   return false;
        // });

        // Product animation
        var animPlaying = false;
        $('.js-link').on('click', function() {
          if(!animPlaying) {
            animPlaying = true;
            var $img = $('.js-full-bracket').attr('src');
            var $gif = $(this).data('gif');
            $('.js-full-bracket').attr('src', $gif);

            setTimeout(function(){
              $('.js-full-bracket').attr('src', $img);
              animPlaying = false;
            }, 3300);

          }
          return false;
        });

        // Smooth scroll
        // http://stackoverflow.com/questions/7717527/smooth-scrolling-when-clicking-an-anchor-link
        // var $root = $('html, body');
        // $('.js-video-link').click(function() {
        //     $root.animate({
        //         scrollTop: $( $.attr(this, 'href') ).offset().top
        //     }, 500);
        //     return false;
        // });


        // Lightbox
        $('.js-video-link').on('click', function() {
          $('.js-lightbox').fadeToggle().addClass('js-toggled');
          $('body').addClass('no-scroll');

          // Play video
          var videoURL = $('.js-youtube-video').prop('src');
          videoURL += "&autoplay=1";
          $('.js-youtube-video').prop('src',videoURL);
          return false;
        });

        var closeLightbox = function() {
          $('.js-lightbox').fadeToggle().removeClass('js-toggled');
          $('body').removeClass('no-scroll');

          // Stop video
          var videoURL = $('.js-youtube-video').prop('src');
          videoURL = videoURL.replace("&autoplay=1", "");
          $('.js-youtube-video').prop('src','');
          $('.js-youtube-video').prop('src',videoURL);

          return false;
        };

        // Lightbox - Close
        $(document).on('click', '.js-close-button', function() {
          closeLightbox();
          return false;
        });

        // Lightbox - Close on escape key
        if ($('.js-lightbox').length) {
          $(document).keyup(function(e) {
            if (e.keyCode === 27) {
              if($('.js-lightbox').hasClass('js-toggled')) {
                closeLightbox();
              }
            }
          });
        }

      },
      finalize: function() {
        // JavaScript to be fired on the home page, after the init JS
      }
    },
    'page_template_template_product': {
      init: function() {
        $('.js-product-plus').on('click', function() {
          $('.js-product-description').slideToggle();
          return false;
        });
      }
    },
    'page_template_template_landing': {
      init: function() {
        // if ($('.js-landing-content').length) {
        //   $('.js-landing-content').matchHeight();
        // }
      }
    }
  };

  // The routing fires all common scripts, followed by the page specific scripts.
  // Add additional events for more control over timing e.g. a finalize event
  var UTIL = {
    fire: function(func, funcname, args) {
      var fire;
      var namespace = Sage;
      funcname = (funcname === undefined) ? 'init' : funcname;
      fire = func !== '';
      fire = fire && namespace[func];
      fire = fire && typeof namespace[func][funcname] === 'function';

      if (fire) {
        namespace[func][funcname](args);
      }
    },
    loadEvents: function() {
      // Fire common init JS
      UTIL.fire('common');

      // Fire page-specific init JS, and then finalize JS
      $.each(document.body.className.replace(/-/g, '_').split(/\s+/), function(i, classnm) {
        UTIL.fire(classnm);
        UTIL.fire(classnm, 'finalize');
      });

      // Fire common finalize JS
      UTIL.fire('common', 'finalize');
    }
  };

  // Load Events
  $(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.
